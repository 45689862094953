<template>
  <div
    class="influencer-testimonial"
    :style="[ringColorCss, titleColorCss]"
    @click="sendScrollData"
  >
    <div class="influencer-testimonial__image-wrapper mobile-format--square">
      <ResponsiveImage
        :lazyload="true"
        v-if="mobileImage.filename || desktopImage.filename"
        :mobile-src="mobileImage.filename"
        :desktop-src="desktopImage.filename"
        :alt="altText"
        mobile-display-size="60vw"
        desktop-display-size="30vw"
        class="influencer-testimonial__image"
      />
      <mp-link
        v-if="$u(link) !== null && active"
        class="influencer-testimonial__image-wrapper__link"
        :to="$u(link)"
      />
    </div>
    <div class="influencer-testimonial__content" :class="displayContent">
      <div
        class="influencer-testimonial__content__title-wrapper"
        :class="desktopAnimeClass"
      >
        <Headline
          headline-type="h2"
          :headline="{ html: title }"
          align-headline="left"
          vertical-margin="margin-none"
          class="influencer-testimonial__content__title"
        />
      </div>
      <p
        class="influencer-testimonial__content__quote"
        :class="desktopAnimeClass"
      >
        &ldquo;{{ quote }}&rdquo;
      </p>
      <div class="influencer-testimonial__content__bottom">
        <p
          class="influencer-testimonial__content__bottom__authority"
          :class="desktopAnimeClass"
        >
          {{ authority }}
        </p>
        <mp-link
          v-if="!!productLink"
          class="influencer-testimonial__content__bottom__product-link"
          :class="desktopAnimeClass"
          :to="$u(productLink)"
        >
          {{ $t('Influencer_Testimonial_Product_Link') }}
        </mp-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'InfluencerTestimonial',
  props: {
    mobileImage: {
      type: Object,
      default: () => {},
    },
    desktopImage: {
      type: Object,
      default: () => {},
    },
    ringColor: {
      type: String,
      default: 'blue',
    },
    title: {
      type: String,
      default: '',
    },
    titleColor: {
      type: String,
      default: 'blue',
    },
    quote: {
      type: String,
      default: '',
    },
    authority: {
      type: String,
      default: '',
    },
    link: {
      type: Object,
      default: () => {},
    },
    productLink: {
      type: Object,
      default: () => {},
    },
    intersectionObserver: {
      validator: (prop) => {
        if (typeof prop === 'object') return 'success'
        return 'warning'
      },
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
    active: {
      type: Boolean,
      default: false,
    },
    scrollDirection: {
      type: String,
      default: '',
    },
    desktopPadding: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      desktop: null,
      display: true,
    }
  },
  computed: {
    altText() {
      if (this.mobileImage.alt) return this.mobileImage.alt
      if (this.desktopImage.alt) return this.desktopImage.alt
      return ''
    },
    ringColorCss() {
      return { '--ring-color': `var(--${this.ringColor})` }
    },
    titleColorCss() {
      return { '--title-color': `var(--${this.titleColor})` }
    },
    desktopAnimeClass() {
      if (this.desktop) return 'desktop-anime'
      return ''
    },
    displayContent() {
      if (this.display) return 'display'
      return ''
    },
    peekImageOffset() {
      if (this.desktopPadding === 'xxsmall') {
        return '160%'
      } else if (this.desktopPadding === 'xsmall') {
        return '150%'
      } else if (this.desktopPadding === 'small') {
        return '145%'
      } else if (this.desktopPadding === 'medium') {
        return '137%'
      } else if (this.desktopPadding === 'large') {
        return '127%'
      } else if (this.desktopPadding === 'xlarge') {
        return '118%'
      } else if (this.desktopPadding === 'xxlarge') {
        return '116%'
      } else if (this.desktopPadding === 'xxxlarge') {
        return '105%'
      }
      return '100%'
    },
  },
  watch: {
    active(val) {
      if (val) {
        const timeline = this.$anime.timeline()
        // left
        if (this.scrollDirection === 'left') {
          timeline.add(this.slideLeft())
        }
        // right
        else if (this.scrollDirection === 'right') {
          timeline.add(this.slideRight())
        }

        if (this.desktop) timeline.add(this.resetImage())
        timeline.play()
      }
      // when not active
      else if (this.scrollDirection === 'right') {
        if (this.desktop) this.peekImageRight().play()
      }
    },
    intersectionObserver(val) {
      if (val !== undefined) {
        this.intersectionObserver.observe(this.$el)
      }
    },
  },
  mounted() {
    if (this.intersectionObserver) {
      this.intersectionObserver.observe(this.$el)
    }
    this.initDesktopQuery()
  },
  beforeDestroy() {
    this.intersectionObserver.unobserve(this.$el)
  },
  methods: {
    slideLeft() {
      return this.$anime({
        targets: this.$el.querySelectorAll(
          '.influencer-testimonial__content .desktop-anime'
        ),
        duration: 700,
        delay: this.$anime.stagger(50, {
          easing: 'easeOutCubic',
        }),
        easing: 'easeOutCubic',
        translateX: [150, 0],
      })
    },
    slideRight() {
      return this.$anime({
        targets: this.$el.querySelectorAll(
          '.influencer-testimonial__content .desktop-anime'
        ),
        duration: 700,
        delay: this.$anime.stagger(50, { easing: 'easeOutCubic' }),
        easing: 'easeOutCubic',
        translateX: [-150, 0],
      })
    },
    resetImage() {
      return this.$anime({
        targets: this.$el.querySelector(
          '.influencer-testimonial__image-wrapper'
        ),
        translateX: '0%',
        duration: 1000,
        easing: 'cubicBezier(0.7, 0, 0, 1)',
        update: (anim) => {
          if (anim.progress > 20) this.display = true
        },
      })
    },
    peekImageRight() {
      return this.$anime({
        targets: this.$el.querySelector(
          '.influencer-testimonial__image-wrapper'
        ),
        translateX: this.peekImageOffset,
        duration: 1000,
        easing: 'cubicBezier(0.7, 0, 0, 1)',
        complete: () => {
          this.display = false
        },
      })
    },
    initDesktopQuery() {
      const desktopQuery = window.matchMedia('(min-width: 1024px)')
      this.desktop = desktopQuery.matches

      desktopQuery.addEventListener('change', (e) => {
        this.desktop = e.matches
      })
    },
    sendScrollData(e) {
      let index
      if (this.desktop) index = this.index

      this.$emit('scrollData', e.currentTarget.offsetLeft, index)
    },
  },
}
</script>
<style lang="scss" scoped>
.influencer-testimonial {
  position: relative;
  width: 70%;
  flex-shrink: 0;
  scroll-snap-align: start;
  z-index: 1;

  &.active {
    z-index: 5;
    .influencer-testimonial__image-wrapper {
      border: 2px solid var(--parent-ring-color, var(--ring-color));
    }

    .influencer-testimonial__content {
      opacity: 1;
    }
  }

  .influencer-testimonial__image-wrapper {
    border-radius: 100%;
    border: 2px solid transparent;

    transition: border 0.3s cubic-bezier(1, 0, 0, 1);
  }

  .influencer-testimonial__image-wrapper__link {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .influencer-testimonial__image {
    margin: spacing('xxsmall');
    border-radius: 100%;
    overflow: hidden;
  }

  .influencer-testimonial__content {
    width: 120%;
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(1, 0, 0, 1);
  }

  .influencer-testimonial__content__title {
    color: var(--parent-title-color, var(--title-color));

    ::v-deep {
      .headline-text {
        @include h--x-large;

        margin-top: spacing('small');
        margin-bottom: 0;
      }
    }
  }

  .influencer-testimonial__content__quote {
    @include h--tiny;

    margin-top: spacing('xsmall');
    font-weight: 300;
  }

  .influencer-testimonial__content__bottom {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  .influencer-testimonial__content__bottom__authority {
    @include caps('large');
  }

  .influencer-testimonial__content__bottom__product-link {
    color: $blue;
  }
}

// Desktop
@media screen and (min-width: $tablet-landscape) {
  .influencer-testimonial {
    display: flex;
    width: 60%;
    gap: spacing('large');
    align-items: center;
    scroll-snap-align: start;

    transition-property: opacity;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;

    &:not(.active) {
      opacity: 0.2;

      .influencer-testimonial__image-wrapper {
        &:hover {
          border: 2px solid $grey-dark;
        }
      }
    }

    &.active {
      .influencer-testimonial__content {
        opacity: 0;

        &.display {
          opacity: 1;
        }
      }
    }

    &:hover {
      opacity: 1;
    }

    .influencer-testimonial__image-wrapper {
      width: 25vw;
      height: fit-content;
      flex-shrink: 0;

      transition: border 0.7s ease-out;
    }

    .influencer-testimonial__content {
      width: 100%;
      flex-shrink: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-right: spacing('small');
    }

    .influencer-testimonial__content__title {
      margin-top: 0;

      ::v-deep {
        .headline-text {
          @include h--x-large;

          margin-top: 0;
          margin-bottom: 0rem;
        }
      }
    }

    .influencer-testimonial__content__bottom {
      flex-wrap: wrap;
    }
  }
}
// High Res Desktop
@media screen and (min-width: $laptop) {
  .influencer-testimonial {
    gap: spacing('xxlarge');

    .influencer-testimonial__content__title {
      margin-top: 0;

      ::v-deep {
        .headline-text {
          @include display--x-large;

          margin-top: 0;
          margin-bottom: 2rem;
        }
      }
    }
  }
}
</style>
